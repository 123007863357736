import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { LoaderState } from "./loader";
@Injectable()
export class LoaderService {
  private loaderSubject = new Subject<LoaderState>();
  loaderState = this.loaderSubject.asObservable();
  private httpRequestSet = new Set<string>();
  constructor() {}
  show() {
    this.loaderSubject.next(<LoaderState>{ loaded: true });
  }
  hide() {
    this.loaderSubject.next(<LoaderState>{ loaded: false });
  }

  showHttpLoader(requestId: string) {
    this.httpRequestSet.add(requestId);
    if(this.httpRequestSet.size === 1) {
      this.loaderSubject.next(<LoaderState>{ loaded: true });
    }
  }

  hideHttpLoader(requestId: string) {
    this.httpRequestSet.delete(requestId);
    if(this.httpRequestSet.size === 0) {
      this.loaderSubject.next(<LoaderState>{ loaded: false });
    }
  }

  resetHttpLoader() {
    this.httpRequestSet.clear();
    this.loaderSubject.next(<LoaderState>{ loaded: false });
  }
}
