import { IColumnDef,IFilterOptions } from "@zetwerk/zet-list";
import { SampleSupplyTypes } from '../constants/e-way-bill';
import { OptionModel } from '../models/tab-model';

export const NOT_ALLOWED_STATUS_RETURN_DISPATCH = {
  CLOSED: 'CLOSED',
  CANCELLED: 'CANCELLED',
  FAILED: 'FAILED'
}

export const PO_TYPES_REF = {
  FMS: 'factory-purchase-order',
  OMS: 'purchase-order'
};

export const DC_RETURN_DISPATCH_STATUS_MAP = {
  DISPATCH: 'DISPATCH',
  RETURN: 'RETURN'
};

export const RETURN_DISPATCH_MAPPER = {
  'DISPATCH': 'Dispatched Goods',
  'RETURN': 'Returned Goods'
}

export const LIST_TABS = [
  {
    name: "CREATED",
    title: 'Active DC',
    status: ['CREATED'],
    countKey: 'CREATED',
    active: true
  },
  {
    name: "CANCELLED",
    title: 'Cancelled/Closed DC',
    status: 'CANCELLED',
    countKey: 'CANCELLED',
  },
  {
    name: "FAILED",
    title: 'Failed',
    status: 'FAILED',
    countKey: 'FAILED',
  }
];

export const STATUS_MAP = {
  CREATED: 'Created',
  CLOSED: 'Closed',
  CANCELLED: 'Cancelled',
  DRAFT: 'Draft',
  FAILED: 'Failed'
}

export const DC_DETAILS_TABS = [
  {
    name: 'DC_DETAILS',
    title: 'DC Details',
    active: true
  },
  {
    name: 'RETURN_OR_DISPATCH',
    title: 'Return or Dispatch',
    active: false
  }
]

export const FILTER_OPTIONS: Array<IFilterOptions> = [
  {
    id: 'deliveryChallanNumber',
    title: 'DC No.',
    type: 'multiselect',
    bindLabel: 'name',
    bindValue: '_id',
    filterModelType: 'serverSide'
  },
  {
    id: 'billToDetails',
    title: 'Bill To',
    type: 'multiselect',
    bindLabel: 'name',
    bindValue: '_id',
    filterModelType: 'serverSide'
  },
  {
    id: 'billFromDetails',
    title: 'Bill From',
    type: 'multiselect',
    bindLabel: 'name',
    bindValue: '_id',
    filterModelType: 'serverSide'
  }
];


export const COLUMN_DEFINITIONS: Array<IColumnDef> = [
  {
    field: 'sequenceNumber',
    headerName: 'DC No.',
    cellClass: 'cell-center',
    cellRenderer: 'commonCellRenderer',
    width: 150,
    valueGetter: (data) => {
      return data?.deliveryChallanNumber || '-';
    }
  },
  {
    field: 'documentDate',
    headerName: 'DC Date',
    cellClass: 'cell-center',
    cellRenderer: 'dateCellRenderer',
    width: 120,
    valueGetter: (data) => {
      return data?.documentDate;
    }
  },
  {
    field: 'billToDetails',
    headerName: 'Bill To',
    cellClass: 'cell-center',
    width: 200,
    cellRenderer: 'subtextCellRenderer',
    valueGetter: (data) => {
      const res = {
        text: data?.billToDetails?.name || '-',
        subText: (data?.billToDetails?.city?`${data?.billToDetails?.city}, `:'')+ (data?.billToDetails?.state || '')
      }
      res.subText = res.subText.replace(/,\s*$/, "");
      return res;
    }
  },
  {
    field: 'billFromDetails',
    headerName: 'Bill From',
    cellClass: 'cell-center',
    width: 200,
    cellRenderer: 'subtextCellRenderer',
    valueGetter: (data) => {
      const res = {
        text: data?.billFromDetails?.name || '-',
        subText: (data?.billFromDetails?.city?`${data?.billFromDetails?.city}, `:'')+ (data?.billFromDetails?.state || '')
      }
      res.subText = res.subText.replace(/,\s*$/, "");
      return res;
    }
  },
  {
    field: 'supplyType',
    headerName: 'Supply Type',
    cellClass: 'cell-center',
    cellRenderer: 'commonCellRenderer',
    width: 180,
    valueGetter: (data) => {
      const supplyType = SampleSupplyTypes[data?.supplyType?.toLowerCase()]?.label;
      return supplyType || '-';
    }
  },
  {
    field: 'dcAmount',
    headerName: 'DC Amount',
    cellClass: 'cell-center',
    cellRenderer: 'currencyCellRenderer',
    width: 140,
    valueGetter: (data) => {
      const res = {
        currencySymbol: '',
        amount: ''
      };
      res['currencySymbol'] = (data?.dcAmount?'&#8377;':'-' );
      res['amount'] = (data?.dcAmount)
      return res;
    }
  }
];

export const COLUMN_DEFINITIONS_RETURN_DISPATCH_LIST: Array<IColumnDef> = [
  {
    field: 'referenceNumber',
    headerName: 'Reference ID',
    cellClass: 'cell-center',
    width: 160,
    valueGetter: (data) => {
      return data.referenceNumber;
    }
  },
  {
    field: 'createdAt',
    headerName: 'Date',
    cellClass: 'cell-center',
    width: 180,
    cellRenderer: 'dateCellRenderer',
    valueGetter: (data) => {
      return data.createdAt;
    }
  },
  {
    field: 'supplierDetails.basicInformation.name',
    headerName: 'Return or Dispatched',
    cellClass: 'cell-center',
    width: 180,
    valueGetter: (data) => {
      return data?.supplierDetails ? data?.supplierDetails?.basicInformation?.name : data?.invoiceDetails?.customerDetails?.name;
    }
  },
  {
    field: 'invoiceDetails.invoiceNumber',
    headerName: 'Invoice No.',
    cellClass: 'cell-center',
    width: 180,
    valueGetter: (data) => {
      return data?.invoiceDetails?.invoiceNumber;
    }
  },
  {
    field: 'type',
    headerName: 'Type',
    cellClass: 'cell-center',
    width: 160,
    cellRenderer: 'statusCellRenderer',
    valueGetter: (data) => {
      const res = {
        statusMap: {},
        status: ''
      };
      res['statusMap'] = DC_RETURN_DISPATCH_STATUS_MAP
      res['status'] = data.type
      return res;
    }
  }
];

export const RETURN_OR_DISPATCH_TEMPLATE_URL = 'https://zetwerk-oms-prod.s3.ap-south-1.amazonaws.com/DC-BULK-TEMPLATE-FOR-RETURN-DISPATCH.xlsx';
export const CREATE_DC_TEMPLATE_URL = 'https://zetwerk-oms-prod.s3.ap-south-1.amazonaws.com/Bulk-Upload-Create-DC-template.xlsx';

export const SampleSubTypes = {
  job_work: <OptionModel>{
    label: 'Job Work',
    value: 'Job Work'
  },
  skd_ckd_lots: <OptionModel>{
    label: 'SKD/CKD/Lots',
    value: 'SKD/CKD'
  },
  recepient_not_known: <OptionModel>{
    label: 'Recipient Not Known',
    value: 'Recipient not known'
  },
  for_own_use: <OptionModel>{
    label: 'For Own Use',
    value: 'For Own Use'
  },
  exhibition: <OptionModel>{
    label: 'Exhibition',
    value: 'Exhibition'
  },
  fairs: <OptionModel>{
    label: 'Fairs',
    value: 'Fairs'
  },
  line_sales: <OptionModel>{
    label: 'Line Sales',
    value: 'Line Sales'
  },
  sales_return: <OptionModel>{
    label: 'Sales Return',
    value: 'Sales Return'
  },
  job_work_returns: <OptionModel>{
    label: 'Job Work Returns',
    value: 'Job Work Returns'
  },
  others: <OptionModel>{
    label: 'Others',
    value: 'Others'
  }
};

export const DOCTYPES = {
  TAX_INVOICE: new OptionModel('Tax Invoice', 'Tax Invoice'),
  BILL_OF_SUPPLY: new OptionModel('Bill of Supply', 'Bill of Supply'),
  DELIVERY_CHALLAN: new OptionModel('Delivery Challan', 'Delivery Challan'),
  DEBIT_NOTE: new OptionModel('Debit Note', 'Debit Note'),
  CREDIT_NOTE: new OptionModel('Credit Note', 'Credit Note'),
  BILL_OF_ENTRY: new OptionModel('Bill of Entry', 'Bill of Entry'),
  OTHERS: new OptionModel('Others', 'Others')
};


export const DC_SUPPLY_SUB_MAP = [
{
    supplyType: SampleSupplyTypes.outward,
    supplySubType: SampleSubTypes.job_work,
    docTypes: [DOCTYPES.DELIVERY_CHALLAN]
  }, {
    supplyType: SampleSupplyTypes.outward,
    supplySubType: SampleSubTypes.skd_ckd_lots,
    docTypes: [DOCTYPES.TAX_INVOICE, DOCTYPES.BILL_OF_SUPPLY, DOCTYPES.DELIVERY_CHALLAN]
  }, {
    supplyType: SampleSupplyTypes.outward,
    supplySubType: SampleSubTypes.recepient_not_known,
    docTypes: [DOCTYPES.DELIVERY_CHALLAN, DOCTYPES.OTHERS]
  }, {
    supplyType: SampleSupplyTypes.outward,
    supplySubType: SampleSubTypes.for_own_use,
    docTypes: [DOCTYPES.DELIVERY_CHALLAN]
  }, {
    supplyType: SampleSupplyTypes.outward,
    supplySubType: SampleSubTypes.exhibition,
    docTypes: [DOCTYPES.DELIVERY_CHALLAN]
  }, {
    supplyType: SampleSupplyTypes.outward,
    supplySubType: SampleSubTypes.fairs,
    docTypes: [DOCTYPES.DELIVERY_CHALLAN]
  }, {
    supplyType: SampleSupplyTypes.outward,
    supplySubType: SampleSubTypes.line_sales,
    docTypes: [DOCTYPES.DELIVERY_CHALLAN]
  }, {
    supplyType: SampleSupplyTypes.outward,
    supplySubType: SampleSubTypes.others,
    docTypes: [DOCTYPES.OTHERS, DOCTYPES.DELIVERY_CHALLAN]
  },
 
  {
    supplyType: SampleSupplyTypes.inward,
    supplySubType: SampleSubTypes.skd_ckd_lots,
    docTypes: [DOCTYPES.TAX_INVOICE, DOCTYPES.BILL_OF_SUPPLY, DOCTYPES.BILL_OF_ENTRY, DOCTYPES.DELIVERY_CHALLAN]
  }, {
    supplyType: SampleSupplyTypes.inward,
    supplySubType: SampleSubTypes.job_work_returns,
    docTypes: [DOCTYPES.DELIVERY_CHALLAN]
  }, {
    supplyType: SampleSupplyTypes.inward,
    supplySubType: SampleSubTypes.sales_return,
    docTypes: [DOCTYPES.DELIVERY_CHALLAN]
  }, {
    supplyType: SampleSupplyTypes.inward,
    supplySubType: SampleSubTypes.exhibition,
    docTypes: [DOCTYPES.DELIVERY_CHALLAN]
  }, {
    supplyType: SampleSupplyTypes.inward,
    supplySubType: SampleSubTypes.fairs,
    docTypes: [DOCTYPES.DELIVERY_CHALLAN]
  }, {
    supplyType: SampleSupplyTypes.inward,
    supplySubType: SampleSubTypes.for_own_use,
    docTypes: [DOCTYPES.DELIVERY_CHALLAN]
  }, {
    supplyType: SampleSupplyTypes.inward,
    supplySubType: SampleSubTypes.others,
    docTypes: [DOCTYPES.OTHERS, DOCTYPES.DELIVERY_CHALLAN]
  }
];