import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoaderService } from './services/loader/loader.service';
import { NotificationService } from './services/notification/notification.service';
import { OmsService } from './services/oms.service';
import { UsersService } from './services/users.service';
import { isURLAggregation } from 'src/app/constants/oms-aggregation-urls';
import { TenantService } from './services/tenant.service';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class Interceptor implements HttpInterceptor {

  static getErrorMessage(error) {
    if (typeof error === 'object' && typeof error['message'] === 'object') {
      const message = error['message'];
      const z = message[Object.keys(message)[0]];
      if (typeof z['properties'] === 'object') {
        return z['properties'].path + ' is ' + z['properties'].type;
      }
      return z.message;
    }
    return error['message'];
  }

  static getParameterByName(name, url) {
    if (!url) {
      url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }


  constructor(
    private _omsService: OmsService,
    private notificationService: NotificationService,
    private loaderService: LoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UsersService,
    private tenantService: TenantService
  ) { 
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        loaderService.resetHttpLoader();
      }
    });
  }

  logout() {
    this._omsService.logOutWithCookie().subscribe(result => {
      localStorage.clear();
      setTimeout(() => {
        this.router.navigate(['login']);
        // need to fix company selection, as of now it's temporary solution.
        window.location.reload();
      }, 500)
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Below line is hack... this is not a best solution. This is for temporary basic;
    // Todo: Remove below line when proper solution comes
    const isFactoryPaymentsUrl = req.url && (req.url.includes('factory-bills') || req.url.includes('all-factory-payment-requests') || req.url.includes('all-factory-credit-notes'));
    const requestId = uuidv4();

    // Whitelist URL's for hiding error notification
    const WHITELIST_URLS = ['bank-statement/validate-excel', 'bank-statement/validate-date-range', 'revert-pdc-payments', 'bank-transaction/revert-advance-mapping', '/revert-ar-ap-tds-receivable', '/validate-collection-reversal','/validate-revert-vfs-finance-repayment'];
    const hideErrorNotification = WHITELIST_URLS.some(url => req.url && req.url.includes(url)) || req.params.get('hideErrorNotification');
    if (!Interceptor.getParameterByName('searchText', req.urlWithParams) && !req.url.endsWith('messages')
      && !req.url.includes('zetwerk-gst-name-exist') && !req.url.includes('config-list') && !req.url.includes('get-distance') && !req.params.get('skipProcessingLoader')) {
      this.loaderService.showHttpLoader(requestId);
    }

    let authReq = null;
    if (this._omsService.getAuthToken()) {

      authReq = req.clone({
        withCredentials: true,
        headers: req.headers
          // .set('Content-Type', 'application/json')
          .set('authorizationtoken', this._omsService.getAuthToken())
          .set('X-Request-Id', requestId )
          .set('X-Service-Version', '1.0.1')
      });

    } else {
      authReq = req.clone({
        headers: req.headers
          .set('Content-Type', 'application/json')
      });
    }

    authReq = authReq.clone({
      headers: authReq.headers.set('ngsw-bypass', 'true')
    });

    const tenantId = this.tenantService.getTenantId();
    if (tenantId) {
      authReq = authReq.clone({
        headers: authReq.headers.set('x-tenant-id', tenantId)
      });
    }

    let setCompanySlug = this._omsService.getSelectedCompany();
    let slug = setCompanySlug;
    // let urlCompanySlug;
    // let urls = this.router.url.split('/');
    // if (urls && urls.length >= 2) {
    //   let urlSlug = urls[2];
    //   let companies = this._omsService.getUserCompany();
    //   let companySlugs = [];
    //   if (companies && companies.length) {
    //     companySlugs = companies.map(company => company.slug);
    //   }
    //   if (companySlugs && companySlugs.length && companySlugs.indexOf(urlSlug) !== -1) {
    //     urlCompanySlug = urlSlug;
    //   }
    // }

    // if (urlCompanySlug && setCompanySlug !== urlCompanySlug) {
    //   slug = urlCompanySlug
    //   this._omsService.setSelectedCompany(slug);
    // }

    // if this is channel partner, add company slug on every oms call
    if ((req.url.includes('oms') || req.url.includes('bis')) && slug){
      if (
        this._omsService.getIsChannelPartner()
        || (!isURLAggregation(req.url && req.url.toString() || ''))
      ) {
        authReq = authReq.clone({
          headers: authReq.headers.set('X-COMPANY-SLUG', slug)
        });
      }
    }

    // send the newly created request
    /**Added condition to not hide loader whne config list api completes - Akshay */
    return next.handle(authReq).pipe(
      tap(res => {
        // Below both if block is hack... this is not a best solution. This is for temporary basic;
        // Todo: Remove below line when proper solution comes
        if (res instanceof HttpResponse && res['url'] && res['url'].includes('factory-bills') && isFactoryPaymentsUrl) {
          this.loaderService.hideHttpLoader(requestId);
        }
        if (res instanceof HttpResponse && res['url'] && !res['url'].includes('config-list') && !isFactoryPaymentsUrl) {
          this.loaderService.hideHttpLoader(requestId);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this.loaderService.hideHttpLoader(requestId);
        if (error.error.statusCode === 'AUTH_TOKEN_INVALID' || error.error.statusCode === 'NO_AUTH_TOKEN' || error.error.message === 'Auth Token is Invalid') {
          const errorMessage = 'Your auth token has been expired, Please login again.';
          this.notificationService.errorNotification(errorMessage, 'Error');
          this.logout();
          return throwError(error);
        }
        if (error.status === 400 && !this._omsService.getSelectedCompany()) {
          const errorMessage = 'Invalid company selection. Please login again and select company';
          this.notificationService.errorNotification(errorMessage, 'Error');
          this.logout();
          return throwError(error);
        }
        let errMsg = '';
        // Client Side Error
        if (error.error instanceof ErrorEvent) {
          errMsg = `Error: ${error.error.message}`;
        } else { // Server Side Error
          if (!navigator.onLine) {
            this.notificationService.errorNotification('Please check your internet connection!', 'Error');
            // Handle offline error
          } else {

            if (environment.maintenance || [503, 504].includes(error.status)) {
              this.router.navigate(['under-maintenance']);
              return;
            }

            errMsg = `<br><b>Error Code:</b> ${error.status},   <b>Message</b>: ${'Something went wrong. Please check later'}`;
            if (error.status === 400 || error.status === 401 || error.status === 403) {
              // if (error.error.statusCode === 'UPDATE_DOCUMENT_FAILED') {
              //   return throwError(error);
              // }
              errMsg = `<br><b>Error Code:</b> ${error.status}<br>  <b>Message</b>: ${Interceptor.getErrorMessage(error.error)}`;
            }

            if (error.status === 500) {
              errMsg = `<br><b>Error Code:</b> ${error.status},   <b>Message</b>: ${'Something went wrong. Please check later'}`;
            }

            if (!hideErrorNotification) {
              this.notificationService.errorNotification(errMsg, 'Error', this.router);
            }
          }

        }
        return throwError(error);
      })
    );
  }

}