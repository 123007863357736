import { IFilterOptions, ITableOptions, ListType } from "@zetwerk/zet-list";
import { DatePipe } from "@angular/common";
import { CurrencySymbolDecodePipe } from "../pipes/currency-symbol-decode.pipe";
import { COA_TYPE } from "src/app/constants/coa-master";
const PAGINATION_SIZE = 10;


export const BANK_TRANSACTION_TABS = [
  {
    title: "Allocation Pending",
    status: "ALLOCATION_PENDING",
    active: true,
  },
  {
    title: "Allocated",
    status: "ALLOCATED",
    active: false,
  }
];

export const BANK_TRANSACTION_TABS_CREDIT = [
  {
    title: "Allocation Pending",
    status: "ALLOCATION_PENDING",
    active: true,
  },
  {
    title: "Allocated",
    status: "ALLOCATED",
    active: false,
  }
];

export const BANK_TRANSACTION_TABS_DEBIT = [
  {
    title: "Allocation Pending",
    status: "ALLOCATION_PENDING",
    active: true,
  },
  {
    title: "Allocated",
    status: "ALLOCATED",
    active: false,
  },
  {
    title: "Reversed",
    status: "REVERSED",
    active: false,
  }
];

export const BANK_TRANSACTION_TABLE_OPTIONS: ITableOptions = {
  type: ListType.CARD,
  rowHeight: 50,
  paginationSize: PAGINATION_SIZE,
  syncURL: true,
  isRowSelectable: false,
  enableFilterBtn: true,
  clearSelection: true,
};

export const BANK_TRANSACTION_FILTER_OPTIONS: Array<IFilterOptions> = [
  {
    id: "bank",
    title: "Select Bank",
    type: "multiselect",
    bindLabel: "bankName",
    bindValue: "bankName",
  },
  {
    id: "bankAccount",
    title: "Select Bank Account",
    type: "multiselect",
    bindLabel: "accountNo",
    bindValue: "accountNo",
  }
];

export const ALLOCATION_PENDING_QUICK_FILTERS = [
  {
    title: "All",
    status: "ALL",
    active: true,
  },
  {
    title: "Yesterday",
    status: "YESTERDAY",
    active: false,
  },
];

export const ALLOCATED_QUICK_FILTERS = [
  {
    title: "All",
    status: "ALL",
    active: true,
  },
  {
    title: "Direct",
    status: "OMS",
    active: false,
  },
  {
    title: "Indirect",
    status: "NON_OMS",
    active: false,
  },
  {
    title: "Unclaimed",
    status: "UNCLAIMED",
    active: false,
  },
];

export const REVERSED_QUICK_FILTERS = [
  {
    title: "Direct",
    status: "OMS",
    active: true,
  },
  {
    title: "Indirect",
    status: "NON_OMS",
    active: false,
  }
];

export const BANK_MAPPER: any[] = [
  {
    bankName: "Axis Bank",
    slug: "AXIS",
  },
  {
    bankName: "Canara Bank",
    slug: "CANARA",
  },
  {
    bankName: "Federal Bank",
    slug: "FEDERAL",
  },
  {
    bankName: "HDFC Bank",
    slug: "HDFC",
  },
  {
    bankName: "Kotak Mahindra Bank Limited",
    slug: "KOTAK",
  },
  {
    bankName: "RBL Bank",
    slug: "RBL",
  },
  {
    bankName: "Citi Bank",
    slug: "CITI",
  },
  {
    bankName: "ICICI Bank Ltd",
    slug: "ICICI",
  },
  {
    bankName: "Central Bank of India",
    slug: "CBI",
  },
  {
    bankName: "IndusInd Bank",
    slug: "INDUS",
  },
  {
    bankName: "IDFC First Bank",
    slug: "IDFC",
  },
  {
    bankName: "Hongkong & Shanghai Banking Corporation",
    slug: "HSBC",
  },
  {
    bankName: "Standard Chartered Bank",
    slug: "SCB",
  },
  {
    bankName: "SBM Bank",
    slug: "SBM",
  },
  {
    bankName: "Development Bank of Singapore",
    slug: "DBS",
  },
  {
    bankName: "Silicon Valley Bank",
    slug: "SVB",
  },
  {
    bankName: "Canara Bank",
    slug: "CANARA",
  },
  {
    bankName: "State Bank Of India",
    slug: "SBI",
  },
  {
    bankName: "Punjab National Bank",
    slug: "PNB",
  },
  {
    bankName: "Yes Bank",
    slug: "PNB",
  },
  {
    bankName: "Bandhan Bank",
    slug: "DEFAULT",
  },
  {
    bankName: "Catholic Syrian Bank",
    slug: "DEFAULT",
  },
  {
    bankName: "Razorpay - Standard Chartered Bank",
    slug: "RAZORPAY_SCB",
  },
  {
    bankName:"Development Bank of Singapore",
    slug:"DBS"
  },
  {
    bankName:"JP Morgan Bank",
    slug:"JPMORGAN"
  }
];

export const STATUS_MAPPER: any = [
  {
    status: "OMS",
    text: "Direct Receipt",
    class: "oms-allocated",
    icon: "oms-allocated",
  },
  {
    status: "NON_OMS",
    text: "Indirect Receipt",
    class: "non-oms",
    icon: "non-oms",
  },
  {
    status: "UNCLAIMED",
    text: "Unclaimed",
    class: "unallocated",
    icon: "unallocated",
  }
];

export const PAYMENTS_STATUS_MAPPER: any = [
  {
    status: "OMS",
    text: "Direct Payment",
    class: "oms-allocated",
    icon: "oms-allocated",
  },
  {
    status: "NON_OMS",
    text: "Indirect Payment",
    class: "non-oms",
    icon: "non-oms",
  },
  {
    status: "UNCLAIMED",
    text: "Unclaimed",
    class: "unallocated",
    icon: "unallocated",
  }
]

export const BANK_TRANSACTION_COLUMN_DEF = [
  {
    field: "transactionNumber",
    headerName: "ZetCollectionID",
    cellClass: "cell-center",
    cellRenderer: "transactionCellRenderer",
    width: 200,
    valueGetter: (data: any) => {
      return {
        transactionNumber: data?.transactionNumber,
        status: data?.paymentAllocationType,
      };
    },
  },
  {
    field: "narration",
    headerName: "Reference / Cheque / Transaction ID - Narration ",
    cellClass: "cell-center",
    cellRenderer: "narrationCellRenderer",
    width: 400,
    valueGetter: (data: any) => {
      return { narration: data?.narration, utrNumber: data?.utrNumber };
    },
  },
  {
    field: "transactionDate",
    headerName: "Date",
    cellClass: "cell-center cell-value",
    cellRenderer: "commonCellRenderer",
    width: 100,
    valueGetter: (data: any) => getDate(data?.transactionDate),
  },
  {
    field: "credit",
    headerName: "Amount Credited",
    cellClass: "cell-center",
    cellRenderer: "currencyCellRenderer",
    width: 200,
    valueGetter: (data: any) => {
      if (data?.transactionType === "CREDIT") {
        return getCurrency(data?.amount, "credit", data);
      }
    },
  },
  {
    field: "debit",
    headerName: "Amount Debited",
    cellClass: "cell-center",
    cellRenderer: "currencyCellRenderer",
    width: 200,
    valueGetter: (data: any) => {
      if (data?.transactionType === "DEBIT") {
        return getCurrency(data?.amount, "debit", data);
      }
    },
  },
  {
    field: "balance",
    headerName: "Closing Balance",
    cellClass: "cell-center",
    cellRenderer: "currencyCellRenderer",
    width: 200,
    valueGetter: (data: any) => getCurrency(data?.runningBalance, "", data),
  },
];

/**
 * Get currency symbol
 * @param data
 * @returns currency symbol
 */
 const currencySymbol = (data: any) => {
  const currencySymbolDecodePipe = new CurrencySymbolDecodePipe();
  return currencySymbolDecodePipe.transform(data?.currencyDetails?.symbol) || '₹';
}

/**
 * Get currency object for currency cell renderer
 * @param amount
 * @param cellClass
 * @param data
 * @returns object
 */
const getCurrency = (
  amount: any,
  cellClass: string = "",
  data: any = {}
) => {
  return {
    currencySymbol: currencySymbol(data),
    amount: amount || 0,
    cellClass,
  }
};

/**
 * Format date
 * @param date
 * @returns formatted date
 */
const getDate = (date: any) => new DatePipe("en-US").transform(date);

export const PAYMENT_ALLOCATION_TYPES: any = [
  {
    label: "Direct Receipt",
    value: "OMS",
  },
  {
    label: "Indirect Receipt",
    value: "NON_OMS",
  },
  {
    label: "Unclaimed",
    value: "UNCLAIMED",
  },
];

export const PAYMENT_AGAINST_LIST: any = [
  {
    name: "Interest Income",
    value: "Interest Income",
  },
  {
    name: "Mutual Fund Gains",
    value: "Mutual Fund Gains",
  },
  {
    name: "Inter Company Transfers",
    value: "Inter Company Transfers",
  },
  {
    name: "Loan Receipts",
    value: "Loan Receipts",
  },
  {
    name: "Sales Invoice Discounting",
    value: "Sales Invoice Discounting",
  },
  {
    name: "BG Discounting",
    value: "BG Discounting",
  },
  {
    name: "Investment Principal",
    value: "Investment Principal",
  },
  {
    name: "Deposit Refund",
    value: "Deposit Refund",
  },
  {
    name: "Hedging",
    value: "Hedging",
  },
  {
    name: "Forex Gain/Loss",
    value: "Forex Gain/Loss",
  },
  {
    name: "Cheque Bounce",
    value: "Cheque Bounce",
  },
  {
    name: "Others",
    value: "Others",
  },
];

export const BANK_TRANSACTION_STATUS_MAPPER: any = {
  ALLOCATION_PENDING: 'ALLOCATION_PENDING',
  ALLOCATED: 'ALLOCATED',
  REVERSED:'REVERSED'
};

export const BANK_TRANSACTION_TYPES_MAPPER: any = {
  DEBIT: 'DEBIT',
  CREDIT: 'CREDIT'
};

export const DEBIT_ALLOCATION_TYPES: any = {
  AUTO: 'AUTO',
  MANUAL: 'MANUAL'
};

export const DEBIT_ALLOCATION_TYPE_MAPPER: any = [
  {
    type: "AUTO",
    text: "Auto",
    class: "auto",
    icon: "auto",
  },
  {
    type: "MANUAL",
    text: "Manual",
    class: "manual",
    icon: "manual",
  },
];
export const PAYMENT_ALLOCATION_TYPES_MAPPER = {
  OMS: "OMS",
  NON_OMS: "NON_OMS",
  UNCLAIMED: "UNCLAIMED",
};

export const ALLOCATION_PENDING = "ALLOCATION_PENDING";
export const ALLOCATED = "ALLOCATED";
export const INR = "INR";

export const TRANSACTION_STATUS_MAPPER = {
  ALLOCATION_PENDING: 'ALLOCATION_PENDING',
  ALLOCATED: 'ALLOCATED',
  REVERSED: 'REVERSED'
};

export const DEBIT_PAYMENT_MARKING_TYPE_LIST = [
  {
    type: 'ADVANCE',
    value: 'ADVANCE'
  },
  {
    type: 'VFS',
    value: 'VFS'
  },
  {
    type: 'PDC',
    value: 'PDC'
  },
  {
    type: 'VFS (MULTI)',
    value: 'MULTI_VFS'
  },
  {
    type: 'INDIRECT',
    value: 'INDIRECT'
  },
  {
    type: 'LC',
    value: 'LC'
  }
];

export const DEBIT_PAYMENT_MARKING_MAPPER = {
  ADVANCE: 'ADVANCE', 
  VFS: 'VFS', 
  PDC: 'PDC',
  AUTO: 'AUTO',
  MULTI_VFS: 'MULTI_VFS',
  INDIRECT:'INDIRECT',
  LC: 'LC'
};

export const REVERSAL_TYPE_MAPPER = {
  DEBIT: 'DEBIT', 
  COLLECTION: 'COLLECTION'
};

export const ACTION_TYPES = {
  MARKING : 'MARKING' ,
  REVERSAL : 'REVERSAL'
};

export const All_ACCOUNT_TYPES  = Object.assign({
  BANK : 'BANK'
},COA_TYPE);

export const BANK_TRANSACTION_LOCAL_STORAGE_FILTER = {
  dateFilter:'bank-transaction-date-filter',
  bankFilter:'bank-transaction-bank-filter',
  quickFilter:'bank-transacton-quick-filter'
}

export const TRANSACTION_TRANSITION_STATES = {
  UNCLAIMED : 'UNCLAIMED' ,
  ALLOCATION_PENDING : 'ALLOCATION_PENDING'
};

export const TAB_TO_QUICK_FILTER_MAP = {
  [BANK_TRANSACTION_STATUS_MAPPER.ALLOCATION_PENDING]: ALLOCATION_PENDING_QUICK_FILTERS,
  [BANK_TRANSACTION_STATUS_MAPPER.ALLOCATED]: ALLOCATED_QUICK_FILTERS,
  [BANK_TRANSACTION_STATUS_MAPPER.REVERSED]: REVERSED_QUICK_FILTERS,
};

export const REVERSED_FILTER_TYPES = {
  TRANSACTION_NUMBER:'TRANSACTION_NUMBER',
  SUPPLIER:'SUPPLIER',
  CUSTOMER:'CUSTOMER',
  PAYMENTMODE:'PAYMENT_MODE',
  ORDER_NUMBER:'ORDER_NUMBER'

}

export const QUICK_FILTER_MAPPER_MAPPER = {
  OMS:'OMS',
  NON_OMS:'NON_OMS'
}

export const SUPPLIER_LC_MARKING_PAYMENT_TYPES = {
  LC_ISSUANCE_CHARGE: 'LC Issuance Charge',
  LC_AMENDMENT_CHARGE: 'LC Amendment Charge',
  PRESENTATION_BANK_CHARGE: 'Presentation Bank Charge', 
  REPAYMENT_BANK_CHARGE: 'Repayment Bank Charges',
  ZETWERK_INTEREST: 'Zetwerk Interest',
  SUPPLIER_BANK_CHARGE: 'Supplier Bank Charges',
  PRINCIPAL_REPAYMENT: 'Repayment', 
}

export const SUPPLIER_LC_MARKING_PAYMENT_TYPES_FOREIGN_CURRENCY = {
  ZETWERK_INTEREST: 'Zetwerk Interest',
  SUPPLIER_BANK_CHARGE: 'Supplier Bank Charges',
  PRINCIPAL_REPAYMENT: 'Repayment', 
}

export const RESTRICTED_BANK_ACCOUNTS_DETAILS_TYPE = 'RESTRICTED_BANK_ACCOUNTS_FOR_SPLIT';


